@import 'assets/styles/variables';

.manual__table {

  .table__cell {
    &--duration {
      min-width: 305px;
    }

    &--createdat {
      min-width: 265px;
    }

    &--usercreated  {
      width: 50%;
    }
    &--doctor {
      width: 100%;
    }

    &--status {
      min-width: 249px;
    }
  }
}

.manual__doctor {
  display: flex;
  align-items: center;
}

.manual__doctor-indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.manual__status {
  display: flex;
  align-items: center;
}

.manual__status-text {
  margin-right: 8px;

  &--canceled {
    color: #ff3e7f;
  }
}

.manual__note {
  position: relative;
  cursor: pointer;

  &:hover {
    .manual__note-bubble {
      display: block;
    }
  }
}

.manual__note-bubble {
  display: none;
  position: absolute;
  background-color: #313a45;
  opacity: 0.9;
  padding: 13px 16px;
  border-radius: 5px;
  color: $white;
  width: 230px;
  top: -22px;
  left: 50%;
  transform: translate(-50%, -100%);
  font-size: 0.875rem;
  z-index: 2;
  cursor: default;

  &::after {
    content: '';
    position: absolute;
    border: 25px solid transparent;
    border-top: 25px solid #313a45;
    left: 50%;
    transform: translateX(-50%);
  }

  &--open {
    display: block;
  }
}

.manual__note-header {
    font-weight: bold;
}

.manual__note-header {
    font-weight: bold;
}

.manual__note-comment {
    margin-top: 5px;
}

.manual__question-mark {
  border-radius: 50%;
  border: 1.5px solid #6c7783;
  opacity: 0.8;
  font-family: $font;
  font-weight: 600;
  font-style: normal;
  width: 19px;
  height: 19px;
  text-align: center;
  font-size: 0.844rem;
  padding-top: 8px;
  line-height: 0;
  color: #6c7783;
  cursor: pointer;
}
